import { useState, useContext, useEffect, useRef } from 'react'
import CardSubCategories from '../components/CardSubCategories'
import ContentCarousel from '../components/ContentCarousel'
import Link from 'next/link'
import FeaturedTile from '../components/FeaturedTile'
import { useAuthAPI } from '../hooks/api.hooks'
import UGCContentBlock from '../components/UGCContentBlock'
import Context from '../context'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Keyboard, Pagination, FreeMode, Navigation } from 'swiper'

import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import 'swiper/css/keyboard'

import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const ProductGroup = ({ template, i }) => {
  const { state } = useContext(Context)
  const [productCards, setProductCards] = useState(template.template)
  const [checkedForProductDetails, setCheckedForProductDetails] = useState(false)
  const currentStore = useRef(state.cart.store?.storenumber)
  const authAPICall = useAuthAPI()

  const assignProductProps = async () => {
    const storeChanged = currentStore.current !== state.cart.store?.storenumber
    // if the store changed then we need to do the comparison
    // against the products that were default assigned to productCards
    const products = storeChanged ? template.template : productCards
    const upcs = products.filter(product => product.upc).map(product => product.upc).join(',')
    const productsUpcToRemove = []
    // Get is_Sellable and PowerReviews for products
    authAPICall('productinfo', 'products', 'GET', {
      store: state.cart.store?.storenumber || publicRuntimeConfig.DEFAULT_STORE,
      upcs: upcs
    }, {}).then((data) => {
      for (const product of products) {
        const returnItem = Array.isArray(data) && data.find(({ upc }) => +product.upc === +upc)
        if (returnItem) {
          product.is_sellable = returnItem.is_sellable
          product.avg_rating = returnItem.avg_rating
          product.rating_count = returnItem.rating_count
          product.in_store_only = `${returnItem.in_store_only}`
          product.inventory = returnItem.qtyOnHand
          product.in_stock = returnItem.qtyOnHand > 0
          product.price = returnItem.price
          product.discount_price = returnItem.discount_price
        } else {
          productsUpcToRemove.push(+product.upc)
        }
      }
      setProductCards(products.filter(x => productsUpcToRemove.indexOf(+x.upc) === -1))
    })
  }

  useEffect(() => {
    const storeChanged = currentStore.current !== state.cart.store?.storenumber
    if (!checkedForProductDetails || storeChanged) {
      assignProductProps()
      setCheckedForProductDetails(true)
    }
    currentStore.current = state.cart.store?.storenumber
  }, [state.cart.store?.storenumber])

  return (
    <div id={template.name} className="aem-content__content-group" key={`product-group-${template.name}-${i}`}>
      <h2 aria-label={template.name}>{template.name}</h2>
      <ContentCarousel data={productCards} index={i} />
    </div>
  )
}

const ContentGroup = ({ template, i }) => {
  return (
    <div id={template.name} className="aem-content__content-group" key={`content-group-${template.name}-${i}`}>
      <h2 aria-label={template.name}>{template.name}</h2>
      <ContentCarousel data={template.template} index={`content-group-${i}`} display={template.display} />
    </div>
  )
}

const CategoryGroup = ({ template, i, page }) => {
  const swiperConfig = {
    modules: [FreeMode, Pagination, Navigation, Keyboard],
    freeMode: true,
    pagination: { clickable: true },
    navigation: {
      nextEl: `.swiper-button-next-subcats-${template.type}-${i}`,
      prevEl: `.swiper-button-prev-subcats-${template.type}-${i}`
    },
    spaceBetween: 16,
    keyboard: true,
    watchOverflow: true
  }

  if (page === AEMPageType.category) {
    const displayedSubs = template.template.filter((sub) => sub.is_displayed)
    return (
      <div id={template.name} className="aem-content__category-group" key={`category-group-${template.name}-${i}`}>
        <h6 aria-label={template.name}>{template.name}</h6>
        <section className="swiper__section content-carousel">
          <div className={`swiper-button-prev-subcats-${template.type}-${i} swiper-navbutton swiper-navbutton-prev`}></div>
          <div className={`swiper-button-next-subcats-${template.type}-${i} swiper-navbutton swiper-navbutton-next`}></div>
          <Swiper key={i} {...swiperConfig} slidesPerView={'auto'}>
            {displayedSubs.map((sub, i) => (
              <SwiperSlide key={i}>
                <CardSubCategories title={sub.name} slug={sub.link || sub.path} image={sub.circle_image} dynamicMediaUrl={sub.dynamicMediaUrl} campaignName={sub.campaign_name} dateReference={sub.date_reference} />
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      </div>
    )
  } else {
    const displayedCat = template.template.filter((obj) => obj.is_displayed)
    return <div id={template.name} className="aem-content__content-group" key={`category-group-${template.slug}-${i}`}>
      <h2 aria-label={template.name}>{template.name}</h2>
      <div className="aem-content__content-group--desktop">
      <section className="swiper__section content-carousel">
          <div className={`swiper-button-prev-subcats-${template.type}-${i} swiper-navbutton swiper-navbutton-prev`}></div>
          <div className={`swiper-button-next-subcats-${template.type}-${i} swiper-navbutton swiper-navbutton-next`}></div>
          <Swiper key={i} {...swiperConfig} slidesPerView={'auto'}>
            {displayedCat.map((obj, i) => (
              <SwiperSlide key={i}>
                <CardSubCategories title={obj.name} slug={obj.link || obj.path} image={obj.circle_image} dynamicMediaUrl={obj.dynamicMediaUrl} campaignName={obj.campaign_name} dateReference={obj.date_reference} />
              </SwiperSlide>
            ))}
          </Swiper>
        </section>
      </div>
      <div className="aem-content__content-group--mobile">
      { displayedCat.length <= 6 && (<div key={i} className="aem-content__content-categories">
        {displayedCat.map((obj, j) => (
          <div data-testid='category-card' key={j} className="aem-content__content-category">
            <CardSubCategories
              title={obj.name}
              image={obj.circle_image}
              slug={obj.slug}
              link={obj.link}
              dynamicMediaUrl={obj.dynamicMediaUrl}
              campaignName={obj.campaign_name}
              dateReference={obj.date_reference}
            />
          </div>
        ))}
      </div>)}
      { displayedCat.length > 6 && (<section className="swiper__section content-carousel">
          <div data-testid='category-card-swiper' className={`swiper-button-prev-subcats-${template.type}-${i} swiper-navbutton swiper-navbutton-prev`}></div>
          <div className={`swiper-button-next-subcats-${template.type}-${i} swiper-navbutton swiper-navbutton-next`}></div>
          <Swiper key={i} {...swiperConfig} slidesPerView={'auto'}>
            {displayedCat.map((obj, i) => (
              <SwiperSlide key={i}>
                <CardSubCategories title={obj.name} slug={obj.link || obj.path} image={obj.circle_image} dynamicMediaUrl={obj.dynamicMediaUrl} campaignName={obj.campaign_name} dateReference={obj.date_reference} />
              </SwiperSlide>
            ))}
          </Swiper>
        </section>)}
      </div>
      <div className="aem-content__content-categories-button" hidden={!template.allCategoriesButton}>
        <Link href="/categories">
          <a className="aem-content__content-categories-link">View all categories</a>
        </Link>
      </div>
    </div>
  }
}

const AEMFeaturedTile = ({ template, i }) => {
  return (
    <div data-testid='featured-tile' id={`featured-tile-${i}`} className="aem-content__content-group" key={`featured-tile-${template.slug}-${i}`}>
      <FeaturedTile data={template} />
    </div>
  )
}

const AEMUGCContentBlock = ({ template, i }) => {
  if (typeof window === 'undefined') {
    return <div></div>
  }
  return (
    <div data-testid='ugc-content-block' className="aem-content__content-group" key={`ugc-content-block-${template.slug}-${i}`}>
      <UGCContentBlock data={template} />
    </div>
  )
}

const Copy = ({ template, i }) => {
  return (
    <div key={`rte-${i}`} dangerouslySetInnerHTML={{ __html: template.value }} className="aem-content__category-group rte" />
  )
}

const componentMap = {
  'category-group': CategoryGroup,
  'content-group': ContentGroup,
  'product-group': ProductGroup,
  'featured-tile': AEMFeaturedTile,
  'ugc-content-block': AEMUGCContentBlock,
  copy: Copy
}

/* eslint-disable no-unused-vars */
export enum AEMPageType {
  category = 'category',
  home = 'home',
}
/* eslint-disable no-unused-vars */

const AEMContent = ({ template, page }) => {
  return (
    template && template.map((template, i) => {
      const Component = componentMap[template.type]
      return Component ? <Component key={i} template={template} i={i} page={page} /> : <div data-testid='aem-no-component' key={i} />
    })
  )
}

export default AEMContent
